import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ChatchampWebsiteChat from "../../components/ChatchampWebsiteChat"

const DemoPage = ({ data }) => {
  const demo_page = data.allPrismicDemoPage.edges.slice(0, 1).pop()
  if (!demo_page) return null

  const chatbotConfigData = demo_page.node.data.chatbot_config[0]
  let config = null

  if (chatbotConfigData) {
    let configData = {
      avatarImageUrl: chatbotConfigData.avatar.url,
      avatarName: chatbotConfigData.avatar_name,
      backgroundColor: chatbotConfigData.background_color,
      headerDescription: chatbotConfigData.header_description,
      logoUrl: chatbotConfigData.logo.url,
    }

    Object.entries(configData).forEach(([key, value]) => {
      if (!value) {
        delete configData[key]
      }
    })

    if (Object.keys(configData).length > 0) config = configData
  }

  return (
    <>
      <Helmet>
        <title>Chatchamp | Conversational Commerce Solution</title>
        <meta
          name="description"
          content="Nutze Chatchamps digitale Produktberater (Chatbots) um auf deiner Website deine Kunden ideal beraten zu können. Jederzeit, ohne Mehraufwand. Erhöhe somit deinen Umsatz und Conversionrate."
        />
      </Helmet>
      <div>
        <ChatchampWebsiteChat
          chatchampId={demo_page.node.data.chatchamp_id}
          payload={demo_page.node.data.payload}
          config={config}
        />
        {
          <img
            src={demo_page.node.data.background_image.url}
            alt="demo-background"
            className="w-full"
          />
        }
      </div>
    </>
  )
}

export const query = graphql`
  query DemoPageQuery($uid: String) {
    allPrismicDemoPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            background_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            industry
            chatchamp_id
            payload
            chatbot_config {
              avatar {
                url
              }
              avatar_name
              background_color
              header_description
              logo {
                url
              }
            }
          }
        }
      }
    }
  }
`
export default DemoPage
