import React from "react"

class ChatchampWebsiteChat extends React.Component {
  src = "https://js.chatchamp.com/bubble.min.js"

  componentDidMount() {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute("src", this.src)
    script.setAttribute("async", true)
    script.setAttribute("data-chatchamp-customer-id", this.props.chatchampId)
    if (this.props.payload) {
      script.setAttribute("data-chatchamp-chatbot-payload", this.props.payload)
    }
    if (this.props.isVisible) {
      script.setAttribute("data-is-visible", this.props.isVisible)
    }
    if (this.props.config) {
      script.setAttribute(
        "data-custom-website-chat-config",
        JSON.stringify(this.props.config)
      )
    }
    head.appendChild(script)
  }

  componentWillUnmount() {
    const head = document.querySelector("head")
    const script = document.querySelector("script")
    head.removeChild(script)
  }

  render() {
    return <div></div>
  }
}

export default ChatchampWebsiteChat
